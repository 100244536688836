/* Import the chosen font */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');

/* Reset default margin and padding */
body, h1 {
    font-family: 'Orbitron', sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}

h2 {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}

/* Center the entire content vertically and horizontally */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #333;
    color: white;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.black-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    transform: translateX(-50%);
    z-index: 0;
}

header, main, footer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

h1 {
    font-size: 2.5em;
    color: #ffcc00; /* Bright, light yellow color */
    text-shadow: 2px 2px 4px #333333; /* Slight shadow for contrast */
    margin-bottom: 1em;
    line-height: 1.1; /* Adjust line height */
    overflow-wrap: break-word; /* Ensure the text wraps */
    word-break: break-word; /* Break long words */
}

h2 {
    font-family: 'Orbitron', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
    margin: 0.5em 0;
    line-height: 1.1; /* Adjust line height */
    overflow-wrap: break-word; /* Ensure the text wraps */
    word-break: break-word; /* Break long words */
}

h2:hover {
    color: #ff6600;
    text-shadow: 0 0 5px #ff6600;
}

.image-container {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #ff6600;
    overflow: hidden;
    margin: 20px 0;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

ul {
    list-style: none;
    padding-left: 0;
}

ul li {
    margin-bottom: 10px;
}

strong {
    color: #ff6600;
}

blockquote {
    font-size: 1.2rem;
    color: #ff6600;
    font-style: italic;
    margin: 20px 0;
}

#backButton {
    background-color: #333;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#backButton:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
}

footer p {
    color: #ffffff;
    margin-top: 1em;
}

/* Mobile-first responsive adjustments */
@media (max-width: 768px) {
    h1 {
        font-size: 2em;
        line-height: 1.2; /* Adjust line height for smaller screens */
    }

    h2 {
        font-size: 1.8em;
        line-height: 1.2; /* Adjust line height for smaller screens */
    }

    blockquote {
        font-size: 1rem;
    }

    #backButton {
        padding: 8px 16px;
        font-size: 0.9em;
    }

    .about-page {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.8em;
        line-height: 1.3; /* Adjust line height further */
    }

    h2 {
        font-size: 1.5em;
        line-height: 1.3; /* Adjust line height further */
    }

    blockquote {
        font-size: 0.9rem;
    }

    #backButton {
        padding: 6px 12px;
        font-size: 0.8em;
    }
}

@media (max-width: 368px) {
    h1 {
        font-size: 1.4em; /* Further reduced font size */
        line-height: 1.4; /* Further reduced line height */
        margin-bottom: 0.5em;
    }

    h2 {
        font-size: 1.2em; /* Further reduced font size */
        line-height: 1.4; /* Further reduced line height */
        margin: 0.3em 0;
    }

    blockquote {
        font-size: 0.8rem;
        margin: 10px 0;
    }

    #backButton {
        padding: 5px 10px;
        font-size: 0.7em;
    }

    .about-page {
        padding: 10px;
    }

    .content-wrapper {
        padding: 10px;
    }

    .image-container {
        padding: 5px;
        margin: 10px 0;
        border-radius: 5px;
    }

    .image-container img {
        border-radius: 4px;
    }

    footer p {
        font-size: 0.8em;
        margin-top: 0.5em;
    }

    /* Hide non-essential elements for smaller screens */
    .black-border {
        display: none;
    }

    /* Simplify shadows and text effects for clarity */
    h1, h2 {
        text-shadow: none;
    }

    h2:hover {
        text-shadow: none;
    }
}

.blood {
    color: #ff6600;
}

/* Title and service animations */
.title-container h1 span,
.services-container h2 span {
    opacity: 0;
    transform: translateX(-10px);
    display: inline-block;
    animation: appear 10s ease forwards;
}

@keyframes appear {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Background and doll animations */
.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.doll-container {
    position: absolute;
    bottom: 0;
    left: -50px;
}

.doll {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #fff;
    animation: walk-animation 2s linear infinite;
}

@keyframes walk-animation {
    0% { transform: translateX(-50%) scaleY(1); }
    50% { transform: translateX(50%) scaleY(0.8); }
    100% { transform: translateX(150%) scaleY(1); }
}

/* Menu hover effect */
.menu li a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;
    border-radius: 20px;
    z-index: 0;
    transform: translate(-50%, -50%);
}

.menu li a:hover::after {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
}

.background {
    width: 100%;
    height: 100vh;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
