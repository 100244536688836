/* Base styles for mobile devices */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #333;
    color: #fff;
}

/* Header */
header {
    padding: 20px 0;
    background-color: #222;
    border-bottom: 2px solid #ff6600;
}

header h1 {
    font-family: 'Orbitron', sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    color: #c0c0c0;
    text-shadow: 2px 2px 4px #ffd700;
    animation: glow 1.5s infinite alternate;
    margin: 0;
}

/* Keyframes for glow effect */
@keyframes glow {
    0% {
        text-shadow: 0 0 5px #ffd700;
    }
    100% {
        text-shadow: 0 0 20px #ff6600;
    }
}

/* Override the H1 hover effect on the Projects page */
.noHoverEffect {
    color: #c0c0c0 !important;
    text-shadow: 2px 2px 4px #ffd700 !important;
    animation: none !important;
}

/* Responsive styles for h2 */
h2 {
    font-size: 1.5em;
    text-align: center;
    margin: 0 0 15px 0;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    color: #ff6600;
}

/* Buttons */
.segment-button {
    background-color: transparent;
    color: #fff;
    border: 2px solid #ff6600;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 10px;
    font-size: 0.9em;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.segment-button:before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.5), transparent);
    border-radius: inherit;
    z-index: -1;
    transition: opacity 0.3s ease;
    opacity: 0;
}

.segment-button:hover:before {
    opacity: 1;
}

.segment-button:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
    border-color: #ccc;
    background-color: #ff6600;
    color: #fff;
}

/* Container */
.product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 90%;
    margin: 0 auto;
    background-color: rgb(50, 50, 50);
    padding: 20px;
    box-sizing: border-box;
}

/* Main Content */
.main-content {
    padding: 15px;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

/* Button Container */
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

/* Footer */
footer {
    background-color: #222;
    margin-top: 30px;
    padding: 10px 0;
    text-align: center;
    border-top: 2px solid #ff6600;
}

footer p {
    color: #fff;
    font-size: 0.9em;
}

/* Back Button */
#backButton {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 0.9em;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#backButton:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
}

/* Text Container */
.text-container {
    border: 2px solid #ffd700;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
}

.text {
    color: #c0c0c0;
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
}

.text-container:hover {
    border-color: #ff6600;
}

/* Responsive Styles for larger screens */
@media (min-width: 600px) {
    .button-container {
        flex-direction: row;
        justify-content: center;
    }

    .segment-button {
        padding: 15px 30px;
        font-size: 1em;
    }

    .main-content {
        padding: 30px;
        margin-bottom: 30px;
    }
}

@media (min-width: 1024px) {
    header h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 2em;
    }

    .product-container {
        max-width: 800px;
    }

    .text-container {
        width: 80%;
        padding: 20px;
    }
}

/* Specific Styles for Small Screens (Apple Watch) */
@media (max-width: 320px) {
    header h1 {
        font-size: 1.2em; /* Further reduced font size */
    }

    h2 {
        font-size: 1em; /* Further reduced font size */
    }

    .segment-button {
        font-size: 0.7em; /* Further reduced button font size */
        padding: 6px 10px; /* Further adjusted padding for better fit */
    }

    footer p {
        font-size: 0.7em; /* Further reduced footer text size */
    }

    #backButton {
        font-size: 0.7em; /* Further reduced back button font size */
        padding: 6px 10px; /* Further adjusted padding */
    }

    .text {
        font-size: 0.8em; /* Adjusted text size for better readability */
    }

    .main-content {
        padding: 8px; /* Further adjusted padding to fit content better */
    }
}
