/* Farm Component Container */
.farmContainer {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 100%;
  margin: auto;
  color: #333;
}

/* Header */
.header {
  background-color: #8b4513;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  color: white;
  text-align: center;
  border-bottom: none;
}

/* Rural Title */
.ruralTitle {
  font-family: 'Georgia', serif;
  font-size: 1.8rem;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  margin: 0;
}

/* Language Toggle Button */
.languageToggle {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  color: #ff6600;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  z-index: 1000;
}

.languageToggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Main Content */
.main {
  padding: 10px;
}

/* Products Section */
.productContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.product {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.product img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Footer */
.footer {
  text-align: center;
  margin-top: 10px;
  color: #666;
}

/* Back Button */
.backButton {
  background-color: #f0f0f0;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  margin-top: 10px;
}

.backButton:hover {
  background-color: #e0e0e0;
}

/* Fieldset */
.fieldset {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

/* Modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modalContent {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 500px;
  position: relative;
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Responsive Design */
@media (min-width: 768px) {
  .productContainer {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .product {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .ruralTitle {
    font-size: 2.5rem;
  }

  .languageToggle {
    top: 20px;
    right: 20px;
    font-size: 1rem;
  }

  .modalContent {
    width: 80%;
  }
}

/* Specific Styles for Apple Watch (Small Screens) */
@media (max-width: 320px) {
  .ruralTitle {
    font-size: 1.2rem; /* Adjust font size for very small screens */
  }

  .product {
    padding: 8px; /* Smaller padding for very small screens */
  }

  .backButton {
    font-size: 0.8rem;
    padding: 4px 8px;
  }

  .footer {
    font-size: 0.7rem;
  }
}
