.clockContainer {
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.7);
  background-color: var(--clock-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
}

.darkTheme {
  --clock-background: #1e1e1e;
  --clock-border-color: #333;
  --clock-shadow-color: #000;
  --text-color: #fff;
  --button-background: #333;
  --button-hover: #555;
  --back-button-background: #333;
  --back-button-color: #fff;
  --clock-numbers-color: #fff;
}

.lightTheme {
  --clock-background: #fff;
  --clock-border-color: #ddd;
  --clock-shadow-color: #ccc;
  --text-color: #000;
  --button-background: #ddd;
  --button-hover: #bbb;
  --back-button-background: #ddd;
  --back-button-color: #000;
  --clock-numbers-color: #000;
}

.clockHeader {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 15px;
}

.clock {
  display: inline-block;
  font-size: 4rem;
  padding: 20px;
  background: linear-gradient(145deg, #1e1e1e, #333333);
  border: 2px solid var(--clock-border-color);
  border-radius: 20px;
  box-shadow: 0 0 20px var(--clock-shadow-color);
  color: var(--clock-numbers-color);
  letter-spacing: 2px;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.digitalClockDark {
  background: linear-gradient(145deg, #1e1e1e, #333333);
  color: #ffffff;
}

.digitalClockLight {
  background: linear-gradient(145deg, #f0f0f0, #dcdcdc);
  color: #000000;
}

.analogClock {
  position: relative;
  width: 300px;
  height: 300px;
  border: 12px solid var(--clock-border-color);
  border-radius: 50%;
  background-color: var(--clock-background);
  box-shadow: 0 0 20px var(--clock-shadow-color);
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clockFace {
  position: relative;
  width: 100%;
  height: 100%;
}

.hand {
  position: absolute;
  width: 50%;
  background: var(--hand-color, #333);
  transform-origin: center left;
  z-index: 2;
  border-radius: 10px;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 15%) rotate(-15deg); /* Center and rotate the hands */
}

.hourHand {
  height: 8px;
  background: linear-gradient(145deg, #333333, #666666);
}

.minuteHand {
  height: 6px;
  background: linear-gradient(145deg, #666666, #999999);
}

.secondHand {
  height: 2px;
  background: linear-gradient(145deg, #ff0000, #cc0000);
}

.centerDot {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: var(--text-color);
  border-radius: 50%;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Button Styles */
.buttonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 15px;
}

.toggleButton, .backButton, .themeButton {
  background-color: var(--button-background);
  color: var(--back-button-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.toggleButton:hover, .backButton:hover, .themeButton:hover {
  background-color: var(--button-hover);
}

/* Responsive Design */
@media (max-width: 600px) {
  .clock {
    font-size: 3rem;
  }
  
  .clockContainer {
    padding: 10px;
  }

  .clockHeader {
    font-size: 1.5rem;
  }

  .analogClock {
    width: 200px;
    height: 200px;
  }

  .hourHand {
    height: 6px;
  }

  .minuteHand {
    height: 4px;
  }

  .secondHand {
    height: 2px;
  }

  .toggleButton,
  .backButton,
  .themeButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Apple Watch Specific Styling */
@media (max-width: 300px) {
  .clock {
    font-size: 2rem;
  }

  .analogClock {
    width: 150px;
    height: 150px;
  }

  .hourHand {
    height: 4px;
  }

  .minuteHand {
    height: 3px;
  }

  .secondHand {
    height: 1px;
  }

  .toggleButton,
  .backButton,
  .themeButton {
    font-size: 0.8rem;
    padding: 8px 12px;
  }
}
