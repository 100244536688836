/* Cube Container */
.cube-container {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  font-family: 'Orbitron', sans-serif;
  touch-action: none; /* Prevents double-tap zooming on mobile */
}

/* Cube */
.cube {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  cursor: pointer;
  transition: transform 0.6s;
}

/* Faces of the Cube */
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  backface-visibility: hidden;
}

/* Face Colors */
.front { background-color: #241d1d; }
.back { background-color: #241d1d; }
.right { background-color: #ff6600; }
.left { background-color: #ff6600; }
.top { background-color: #ff6600; }
.bottom { background-color: #ff6600; }

/* Face Positioning */
.front  { transform: translateZ(100px); }
.back   { transform: rotateY(180deg) translateZ(100px); }
.right  { transform: rotateY(90deg) translateZ(100px); }
.left   { transform: rotateY(-90deg) translateZ(100px); }
.top    { transform: rotateX(90deg) translateZ(100px); }
.bottom { transform: rotateX(-90deg) translateZ(100px); }

/* Rotation Animations */
.rotate-Y {
  animation: rotateY 6s infinite linear;
}

.rotate-X {
  animation: rotateX 6s infinite linear;
}

.rotate-Z {
  animation: rotateZ 6s infinite linear;
}

@keyframes rotateX {
  from { transform: rotateX(0deg); }
  to { transform: rotateX(360deg); }
}

@keyframes rotateY {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(360deg); }
}

@keyframes rotateZ {
  from { transform: rotateZ(0deg); }
  to { transform: rotateZ(360deg); }
}

/* Mobile Styling */
@media (max-width: 480px) {
  .cube-container {
    width: 150px;
    height: 150px;
  }

  .face {
    font-size: 1.2em;
  }
}
