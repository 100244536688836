/* MotorcycleParts.module.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #faf3e0;
}

.header {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.customTitle {
    font-size: 5rem;
    color: #ff4500;
    margin-bottom: 20px;
    text-shadow: 0 0 10px #ff4500, 0 0 15px #ff4500, 0 0 20px #ff4500;
    font-family: 'Permanent Marker', cursive;
    transition: font-size 0.5s, color 0.5s, transform 0.5s;
}

.customTitle:hover {
    color: #ffd700;
    transform: scale(1.05);
}

.banner {
    background-color: #000;
    color: #00ff00;
    padding: 10px 0;
    border-radius: 5px;
    text-shadow: 0 0 5px #ff9900, 0 0 10px #ff9900, 0 0 15px #ff9900, 0 0 20px #ff9900, 0 0 25px #ff9900;
}

.slogan {
    color: #fff;
    font-family: 'Orbitron', sans-serif;
    font-size: 2rem;
}

.cartIconContainer {
    position: absolute;
    top: 10px;
    right: 20px;
}

.cartIconContainer a {
    color: white;
    position: relative;
    text-decoration: none;
}

.cartIconContainer svg {
    fill: #ff4500;
    transition: fill 0.3s ease-in-out;
}

.cartIconContainer svg:hover {
    fill: #ffd700;
}

.cartCount {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff4500;
    color: #fff;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8rem;
    font-weight: bold;
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.productList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.product {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.3s;
    text-align: center;
}

.product:hover {
    transform: translateY(-10px);
}

.productImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.productContent {
    margin-top: 15px;
}

.productContent h2 {
    color: #333; /* Default color for the product name */
    font-size: 1.5rem;
    font-weight: bold;
    transition: color 0.3s; /* Smooth transition for color changes */
}

.productContent h2:hover {
    color: #ff4500; /* Custom orange color on hover */
}

.productContent p {
    color: #666; /* Text color for product descriptions */
}

.price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.buttonGroup {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.viewDetailsButton,
.paymentOptionsButton,
.backButton {
    background-color: #ff4500;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 5px;
}

.viewDetailsButton:hover,
.paymentOptionsButton:hover,
.backButton:hover {
    background-color: #ff9900;
}

/* Updated styles for product details */
.productDetails {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    flex-direction: row; /* Default for larger screens */
}

.productDetails .productImage {
    max-width: 400px;
    height: auto;
    border-radius: 10px;
}

.productDetails .productContent {
    flex: 1;
}

.features ul {
    padding-left: 20px;
}

.features li {
    list-style-type: disc;
    margin-bottom: 5px;
    color: #555;
}

.footer {
    text-align: center;
    margin-top: 20px;
    padding: 10px 0;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    font-family: 'Orbitron', sans-serif;
}

/* Mobile-first adjustments */
@media screen and (max-width: 768px) {
    .productDetails {
        flex-direction: column; /* Stack image and content vertically */
        text-align: center; /* Center content for better readability */
    }

    .productDetails .productImage {
        max-width: 100%; /* Ensure image fits within the container */
    }

    .productDetails .productContent {
        margin-top: 15px; /* Add some space between image and content */
    }
}

/* Keyframes for enhanced spiral effect */
@keyframes enhancedSpiralEffect {
    0% {
        transform: scale(1) rotate(0deg) translate(0, 0);
        opacity: 1;
    }
    50% {
        transform: scale(0.5) rotate(180deg) translate(50px, -50px);
        opacity: 0.7;
    }
    100% {
        transform: scale(0.3) rotate(360deg) translate(100px, -100px);
        opacity: 0;
    }
}

.animateToCart {
    position: fixed;
    z-index: 1000;
    transition: all 1s ease-in-out;
    animation: enhancedSpiralEffect 1s forwards;
}
