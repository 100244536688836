/* Global styles */
body {
    background-color: rgb(50, 50, 50);
    color: #ffffff;
    margin: 0;
    padding: 0;
    font-family: 'Orbitron', sans-serif;
}

body, h1, h2 {
    margin: 0;
    padding: 0;
    font-family: 'Orbitron', sans-serif;
}

/* Center the entire content vertically and horizontally */
.custom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
}

/* Styles for the title container */
.title-container {
    position: relative;
    text-align: center;
    padding: 20px;
    margin-bottom: 40px;
}

.title-container h1 {
    font-size: 2em;
    color: #ffffff;
    text-shadow: 2px 2px 2px #f7f7f7;
    line-height: 1.2;
}

.title-container h1 span {
    display: inline-block;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    color: #ffffff;
}

.title-container h1 span.orange-letter {
    color: #ff6600;
}

.title-container h1 span.small-copy {
    font-size: 0.3em;
    -ms-text-combine-horizontal: bottom;
}

.typing-animation {
    animation: typeLetter 0.5s forwards;
}

@keyframes typeLetter {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.title-container h1 span:last-child {
    transition-delay: 0.5s;
}

@keyframes slideFromBottom {
    0% {
        transform: translateY(100vh) scale(0.5);
        opacity: 0;
    }
    50% {
        transform: translateY(20vh) scale(1.5);
        opacity: 1;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

.title-container h2 {
    color: #ffffff;
    font-size: 1.2em;
    text-shadow: 2px 2px 2px #ffffff;
    animation: slideFromBottom 3s ease forwards;
    position: relative;
    margin-top: 20px;
}

/* Style for the navigation menu */
.custom-nav {
    background-color: #222222;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Adjusted custom-menu for horizontal layout even on small screens */
.custom-menu {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line if necessary */
    gap: 15px; /* Space between buttons */
    width: 100%;
}

/* Style for each button */
.custom-menu li {
    margin: 0;
}

.custom-menu li a {
    position: relative;
    display: inline-block;
    padding: 20px 40px; /* Increased padding for larger buttons */
    border-radius: 50px; /* Rounded buttons */
    font-weight: bold;
    background: linear-gradient(to bottom, #ff6600, #ff9900);
    color: #222222;
    text-decoration: none;
    overflow: hidden;
    transition: transform 0.2s, background-color 0.2s, color 0.2s, box-shadow 0.2s;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3); /* Increased shadow for prominence */
    min-width: 120px; /* Ensures a minimum button size */
    text-align: center;
    font-size: 1.1em; /* Larger text size for buttons */
}

.custom-menu li a:hover {
    background: linear-gradient(to bottom, #ff9900, #ff6600);
    color: #ffffff;
    transform: translateY(-3px); /* Slight lift on hover */
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);
}

.custom-menu li a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s;
}

.custom-menu li a:hover::before {
    transform: scaleX(1);
    transform-origin: right;
}

/* Styles for the content sections */
.custom-content {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.custom-content h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #c0c0c0;
}

.custom-content h1 span {
    color: #ff6600;
}

.custom-content h2 {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 2px 2px 4px #ff6600;
}

/* General link styles */
.custom-content a {
    text-decoration: none;
    color: inherit;
}

.custom-content h1 a {
    color: #ffffff;
    text-shadow: 2px 2px 2px #f7f7f7;
}

.custom-content h1 a:hover {
    color: #ff6600;
}

.tagline {
    font-size: 1em;
    opacity: 0;
    animation: moveSubtitle 10s ease forwards;
    color: #ffffff;
}

@keyframes moveSubtitle {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

footer {
    text-align: center;
    margin-top: 20px;
}

footer p {
    color: #ffffff;
}

footer p .small-copy {
    font-size: 0.8em;
    vertical-align: middle;
}

.custom-content:hover {
    background-color: #ff6600;
    color: #fff;
    transform: scale(1.05);
}

.custom-container .custom-menu,
.custom-container .custom-menu2 {
    display: flex;
    justify-content: center;
    padding-left: 0;
}

.custom-container .custom-menu li,
.custom-container .custom-menu2 li {
    list-style: none;
    margin: 0 10px;
}

/* Override the H1 hover effect on the Home page */
.noHoverEffect {
    color: #ffffff !important;
    text-shadow: 2px 2px 4px #ffd700 !important;
    animation: none !important;
}

/* Larger screens */
@media (min-width: 480px) {
    .title-container h1 {
        font-size: 2.5em;
    }

    .title-container h2 {
        font-size: 1.5em;
    }

    .custom-nav {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .custom-menu {
        flex-direction: row;
    }

    .custom-menu li {
        margin: 10px;
    }

    .custom-content h1 {
        font-size: 2em;
    }

    .custom-content h2 {
        font-size: 1.5em;
    }

    .custom-content p {
        font-size: 1.2em;
    }

    .tagline {
        font-size: 1.2em;
    }

    footer {
        margin-top: 30px;
    }
}

@media (min-width: 768px) {
    .title-container h1 {
        font-size: 3em;
    }

    .title-container h2 {
        font-size: 2em;
    }

    .custom-nav {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .custom-menu li {
        margin: 10px 20px;
    }

    .custom-content h1 {
        font-size: 2.5em;
    }

    .custom-content h2 {
        font-size: 2em;
    }

    .custom-content p {
        font-size: 1.5em;
    }

    .tagline {
        font-size: 1.5em;
    }

    footer {
        margin-top: 50px;
    }
}

@media (min-width: 1024px) {
    .title-container h1 {
        font-size: 4em;
    }

    .title-container h2 {
        font-size: 2.5em;
    }

    .custom-menu li {
        margin: 20px;
    }

    .custom-content h1 {
        font-size: 3em;
    }

    .custom-content h2 {
        font-size: 2.5em;
    }

    .custom-content p {
        font-size: 2em;
    }

    .tagline {
        font-size: 2em;
    }

    footer {
        margin-top: 60px;
    }
}

@media (max-width: 480px) {
    body {
        padding: 10px;
    }

    .custom-container {
        padding: 20px;
    }

    .title-container {
        padding: 10px;
        margin-bottom: 20px;
    }

    .title-container h1 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .title-container h2 {
        font-size: 1em;
        margin-top: 10px;
    }

    .custom-nav {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .custom-menu {
        flex-direction: column;
        gap: 10px;
    }

    .custom-menu li a {
        padding: 15px 30px;
        font-size: 1em;
    }

    .custom-content {
        padding: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 1em;
    }

    .custom-content h1 {
        font-size: 1.2em;
    }

    .custom-content h2 {
        font-size: 1em;
    }

    .tagline {
        font-size: 0.9em;
    }

    footer p {
        font-size: 0.9em;
    }
}

@media (max-width: 368px) {
    body {
        padding: 5px;
    }

    .custom-container {
        padding: 10px;
    }

    .title-container {
        padding: 5px;
        margin-bottom: 20px;
    }

    .title-container h1 {
        font-size: 1.1em;
        line-height: 1.1; /* Adjust line height to prevent text from wrapping */
        text-shadow: none; /* Simplify shadows for clarity */
        margin-bottom: 10px;
        word-spacing: -2px; /* Adjust word spacing to keep text in a single line */
    }

    .title-container h2 {
        font-size: 0.9em;
        margin-top: 10px;
        text-shadow: none;
    }

    .custom-nav {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-shadow: none; /* Simplify shadow to reduce visual clutter */
    }

    .custom-menu {
        flex-direction: column; /* Ensure a single-column layout */
        gap: 10px; /* Space out the buttons vertically */
    }

    .custom-menu li a {
        padding: 10px 20px;
        font-size: 0.9em;
        box-shadow: none; /* Simplify shadows */
    }

    .custom-content {
        padding: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 0.9em;
    }

    .custom-content h1 {
        font-size: 1em;
    }

    .custom-content h2 {
        font-size: 0.9em;
        text-shadow: none;
    }

    .tagline {
        font-size: 0.8em;
    }

    footer p {
        font-size: 0.8em;
    }

    /* Hide non-essential elements for smaller screens */
    .title-container h1 span.small-copy,
    .custom-nav,
    .tagline {
        display: none;
    }
}
